import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getContent } from '../reducers/content'
import { setupClient } from '../reducers/cart'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import CssDog from '../components/CssDog'

const withContent = WrappedComponent => {
  return class extends React.Component {
    async componentDidMount() {
      if (
        isEmpty(this.props.content) ||
        isEmpty(this.props.collections) ||
        isEmpty(this.props.products)
      ) {
        await this.props.setupClient()
        await this.props.getContent()
      }
    }

    render() {
      if (
        isEmpty(this.props.content) ||
        isEmpty(this.props.collections) ||
        isEmpty(this.props.products)
      ) {
        return <CssDog />
      }
      return <WrappedComponent match={this.props.match} />
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getContent,
      setupClient
    },
    dispatch
  )

const composedHoc = compose(
  connect(
    state => ({
      content: state.content,
      collections: state.collections,
      products: state.products
    }),
    mapDispatchToProps
  ),
  withContent
)

export default composedHoc
