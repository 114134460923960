import React from 'react'
import { TextField, Chip, IconButton, InputAdornment } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { applyCoupon, removeCoupon, setCartMessage } from '../../reducers/cart'
import CloseIcon from '@material-ui/icons/Close'
import ApplyIcon from '@material-ui/icons/Done'
import { drawerWidths } from '../CartDrawer'

const styles = theme => ({
  textField: {
    ...drawerWidths(theme)
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'baseline'
  }
})

class CouponEntry extends React.Component {
  state = {
    value: '',
    dialog: null
  }

  closeDialog = () => {
    this.setState({ dialog: null })
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.props.classes.flexContainer}>
          <TextField
            id="Coupon Code"
            label="Coupon Code"
            variant="outlined"
            className={this.props.classes.textField}
            value={this.state.value}
            onChange={e => this.setState({ value: e.target.value })}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Apply Coupon"
                    disabled={this.state.value.trim().length === 0}
                    color="primary"
                    onClick={() => this.props.applyCoupon(this.state.value)}>
                    <ApplyIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {this.props.discounts &&
            this.props.discounts.map(d => (
              <Chip
                label={d}
                onDelete={() =>
                  this.setState({ value: '' }, () => this.props.removeCoupon(d))
                }
                color="primary"
              />
            ))}
        </div>
        {this.props.cartMessage && (
          <div>
            {this.props.cartMessage}
            <IconButton
              onClick={() => this.props.setCartMessage(null)}
              aria-label="Close">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  discounts: state.cart.data.discounts,
  cartMessage: state.cart.cartMessage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyCoupon, removeCoupon, setCartMessage }, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CouponEntry)
)
