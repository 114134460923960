import React from 'react'
import { Drawer, Typography, Button } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeCart } from '../../reducers/cart'
import { withStyles } from '@material-ui/core/styles'
import { groupedCustomItems, nonCustomLineItems, numItems } from './selectors'
import CustomItem from './CustomItem'
import NonCustomItem from './NonCustomItem'
import CouponEntry from './CouponEntry'
import { formatCurrency } from '../../helpers/formatter'

export const drawerWidths = theme => ({
  width: '40vw',
  [theme.breakpoints.down('sm')]: {
    width: '90vw'
  },
  [theme.breakpoints.up('lg')]: {
    width: '22vw'
  }
})

const styles = theme => ({
  drawer: {
    height: '100%',
    background: theme.palette.grey[100],
    width: '50vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30vw'
    }
  },
  contents: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit
  },
  productSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    marginTop: theme.spacing.unit,
    ...drawerWidths(theme)
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '5vh'
  },
  shipNotification: {
    ...drawerWidths(theme),
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  subtotal: {
    ...drawerWidths(theme),
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between'
  },
  hr: {
    ...drawerWidths(theme)
  }
})

const CartDrawer = props => (
  <Drawer
    classes={{ paper: props.classes.drawer }}
    anchor="right"
    open={props.open}
    onClose={props.closeCart}>
    <div className={props.classes.contents}>
      <div className={props.classes.topRow}>
        <Typography variant="h5">Your Cart</Typography>
        <Button
          color="primary"
          aria-label="Close"
          size="medium"
          onClick={props.closeCart}>
          Keep Shopping
          <ArrowForwardIcon />
        </Button>
      </div>
      <div className={props.classes.productSection}>
        {Object.keys(props.customItems).map(design => (
          <CustomItem
            key={design}
            design={design}
            items={props.customItems[design]}
          />
        ))}
      </div>
      <div className={props.classes.productSection}>
        {props.nonCustomLineItems.map(lineItem => (
          <NonCustomItem key={lineItem.id} lineItem={lineItem} />
        ))}
        <CouponEntry />
        <hr className={props.classes.hr} />
        {Object.keys(props.customItems).length > 0 && (
          <div className={props.classes.shipNotification}>
            Your order contains handmade custom items
          </div>
        )}
        <div className={props.classes.subtotal}>
          <span>Handling time:</span>
          <span>
            {Object.keys(props.customItems).length > 0 ? 'Max 10 Days' : 'ASAP'}
          </span>
        </div>
        <div className={props.classes.subtotal}>
          <span>Shipping:</span>
          <span>Calculated at checkout</span>
        </div>
        <div className={props.classes.subtotal}>
          <span>Subtotal:</span>
          <span>
            <strong>
              {props.numItems === 0
                ? formatCurrency(0)
                : formatCurrency(props.cost)}
            </strong>
          </span>
        </div>
        <Button
          fullWidth
          className={props.classes.button}
          variant="contained"
          color="secondary"
          disabled={props.checkoutDisabled}
          onClick={() => window.open(props.checkoutUrl)}>
          Check Out
        </Button>
      </div>
    </div>
  </Drawer>
)

const mapStateToProps = state => ({
  open: state.cart.open,
  checkoutDisabled: state.cart.lineItems.length === 0,
  customItems: groupedCustomItems(state),
  nonCustomLineItems: nonCustomLineItems(state),
  cost: state.cart.data.total,
  checkoutUrl: state.cart.data.checkoutUrl,
  numItems: numItems(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeCart
    },
    dispatch
  )

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CartDrawer)
)
