import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import products from './products'
import session from './session'
import collections from './collections'
import content from './content'
import orderItems from './orderItems'
import orderForm from './orderForm'
import cart from './cart'
import variants from './variants'

const persistConfig = (key, version) => ({
  key,
  storage,
  version,
  migrate: createMigrate(migrations[key], { debug: true })
})

const migrations = {
  session: {
    0: state => ({
      ...state
    })
  },
  cart: {
    0: state => ({
      ...state
    })
  }
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    products,
    collections,
    content,
    orderItems,
    orderForm,
    variants,
    cart: persistReducer(
      { ...persistConfig('cart', 0), whitelist: ['checkoutId'] },
      cart
    ),
    session: persistReducer(
      { ...persistConfig('session', 0), whitelist: ['token'] },
      session
    )
  })
