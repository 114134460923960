import { find, compact, omit } from 'lodash'
import { activeStore } from '../App'
import shopify from 'shopify-buy'
import { FETCH_COLLECTIONS } from './collections'
import { FETCH_PRODUCTS } from './products'
import { RESET } from './orderForm'

export const SAVE_CLIENT = 'cart/SAVE_CLIENT'
export const OPEN_CART = 'cart/OPEN_CART'
export const CLOSE_CART = 'cart/CLOSE_CART'
export const SET_LINE_ITEMS = 'cart/SET_LINE_ITEMS'
export const SET_DATA = 'cart/SET_DATA'
export const SET_CART_MESSAGE = 'cart/SET_CART_MESSAGE'

export const domain = 'shopify.dunedogcollective.com'
export const storefrontAccessToken = 'c649083749eaf08ccaecdbe1ac9b1cbf'

const initialState = {
  client: null,
  open: false,
  checkoutId: null,
  lineItems: [],
  cartMessage: null,
  data: {
    total: 0,
    checkoutUrl: null,
    couponCode: null,
    discounts: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CART:
      return {
        ...state,
        open: true
      }

    case CLOSE_CART:
      return {
        ...state,
        open: false
      }

    case SET_LINE_ITEMS:
      return {
        ...state,
        open: action.open,
        lineItems: action.lineItems
      }

    case SAVE_CLIENT:
      return {
        ...state,
        client: action.client,
        checkoutId: action.checkoutId || state.checkoutId
      }

    case SET_CART_MESSAGE:
      return {
        ...state,
        cartMessage: action.cartMessage
      }

    case SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...omit(action, 'type')
        }
      }

    default:
      return state
  }
}

export const setupClient = () => async dispatch => {
  const { client, checkoutId } = activeStore.getState().cart
  if (!client) {
    const client = await shopify.buildClient({ domain, storefrontAccessToken })
    client.collection
      .fetchAllWithProducts({ first: 250, productsFirst: 250 })
      .then(collections => {
        dispatch({ type: FETCH_COLLECTIONS, collections })
      })
    client.product.fetchAll(250).then(products => {
      dispatch({ type: FETCH_PRODUCTS, products })
    })
    if (checkoutId) {
      await client.checkout.fetch(checkoutId).then(checkout => {
        if (checkout) {
          if (checkout.completedAt) {
            dispatch(createNewCheckout(client))
          } else {
            dispatch({ type: SAVE_CLIENT, client })
            dispatch(updateData(checkout))
            dispatch({
              type: SET_LINE_ITEMS,
              lineItems: checkout.lineItems,
              open: false
            })
          }
        } else {
          dispatch(createNewCheckout(client))
        }
      })
    } else {
      await dispatch(createNewCheckout(client))
    }
    return true
  }
}

export const createNewCheckout = client => async dispatch => {
  await client.checkout.create().then(checkout => {
    dispatch({ type: SAVE_CLIENT, client, checkoutId: checkout.id })
  })
}

export const openCart = () => dispatch => {
  dispatch({ type: OPEN_CART })
}

export const closeCart = () => dispatch => {
  dispatch({ type: CLOSE_CART })
}

export const setCartMessage = cartMessage => dispatch => {
  dispatch({ type: SET_CART_MESSAGE, cartMessage })
}

export const applyCoupon = discountCode => dispatch => {
  const { client, checkoutId } = activeStore.getState().cart
  client.checkout.addDiscount(checkoutId, discountCode).then(checkout => {
    if (
      !(
        checkout &&
        checkout.discountApplications &&
        checkout.discountApplications.map(d => d.code)[0] === discountCode
      )
    ) {
      dispatch(setCartMessage(`Coupon Code ${discountCode} is invalid`))
    }
    dispatch(updateData(checkout))
  })
}

export const removeCoupon = discountCode => dispatch => {
  const { client, checkoutId } = activeStore.getState().cart
  client.checkout.removeDiscount(checkoutId, discountCode).then(checkout => {
    dispatch(updateData(checkout))
  })
}

export const updateData = checkout => dispatch => {
  const discounts =
    checkout &&
    checkout.discountApplications &&
    checkout.discountApplications.map(d => d.code)
  dispatch({
    type: SET_DATA,
    total: checkout.totalPrice,
    checkoutUrl: checkout.webUrl,
    discounts
  })
}

export const addRegularItemToCard = (variantId, quantity) => dispatch => {
  const { client, checkoutId } = activeStore.getState().cart
  const newLineItems = [{ variantId, quantity }]
  client.checkout.addLineItems(checkoutId, newLineItems).then(checkout => {
    dispatch(updateData(checkout))
    dispatch({
      type: SET_LINE_ITEMS,
      lineItems: checkout.lineItems,
      open: true
    })
  })
}

export const removeVariantFromCart = variantId => dispatch => {
  const { client, checkoutId } = activeStore.getState().cart
  const lineItemsToRemove = [variantId]
  client.checkout
    .removeLineItems(checkoutId, lineItemsToRemove)
    .then(checkout => {
      dispatch(updateData(checkout))
      dispatch({
        type: SET_LINE_ITEMS,
        lineItems: checkout.lineItems,
        open: true
      })
    })
}

export const addCustomOrderToCart = (
  productsInCart,
  shopifyCustomCollection,
  designInfo
) => dispatch => {
  const { client, checkoutId } = activeStore.getState().cart

  const customAttributes = Object.keys(designInfo).reduce(
    (acc, key) =>
      designInfo[key] ? [...acc, { key, value: designInfo[key] }] : acc,
    []
  )

  const rawCart = Object.keys(productsInCart).map(itemKey => {
    const item = productsInCart[itemKey]
    const rootItem = find(
      shopifyCustomCollection.products,
      product => product.title === itemKey
    )
    const variant = rootItem.variants[0]
    if (!variant) return null
    return {
      variantId: variant.id,
      quantity: item.quantity,
      customAttributes
    }
  })
  const newLineItems = compact(rawCart)

  client.checkout
    .addLineItems(checkoutId, newLineItems)
    .then(checkout => {
      dispatch(updateData(checkout))
      dispatch({ type: RESET })
      dispatch({
        type: SET_LINE_ITEMS,
        lineItems: checkout.lineItems,
        open: true
      })
    })
    .catch(console.log)
}
