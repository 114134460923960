import React from 'react'
import { Typography, Paper, Fab } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { removeVariantFromCart } from '../../reducers/cart'
import { drawerWidths } from '../CartDrawer'

const styles = theme => ({
  paper: {
    ...drawerWidths(theme),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  price: {
    fontWeight: 'bold'
  },
  designName: {
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  productsHeader: {
    textAlign: 'center'
  },
  product: {
    marginBottom: theme.spacing.unit * 2,
    position: 'relative',
    display: 'flex'
  },
  imageDelete: {
    position: 'absolute',
    color: '#fff',
    backgroundColor: '#AA7777',
    top: 0,
    right: 0
  },
  hr: {
    width: '60%'
  },
  details: {
    marginTop: theme.spacing.unit
  },
  responsiveImage: {
    width: '10vw',
    height: '15vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    marginRight: theme.spacing.unit
  }
})

const CustomItem = props => (
  <Paper className={props.classes.paper}>
    <Typography variant="h6" className={props.classes.designName}>
      {props.design}
    </Typography>
    <hr className={props.classes.hr} />
    <Typography variant="h6" className={props.classes.productsHeader}>
      Products
    </Typography>
    {props.items.map(product => (
      <div key={product.id} className={props.classes.product}>
        <div
          style={{
            backgroundImage: `url(${get(product.variant, 'image.src')})`
          }}
          className={props.classes.responsiveImage}
        />
        <div className={props.classes.details}>
          <Typography variant="h6">Custom {product.title}</Typography>
          <Typography variant="body1">{`$${get(
            product.variant,
            'price'
          )}`}</Typography>
          <Typography variant="body1">Qty {product.quantity}</Typography>
        </div>
        <Fab
          onClick={() => props.removeVariantFromCart(product.id)}
          size="small"
          aria-label="Remove Image"
          className={props.classes.imageDelete}>
          <DeleteIcon />
        </Fab>
      </div>
    ))}
  </Paper>
)

const mapDispatchToProps = dispatch =>
  bindActionCreators({ removeVariantFromCart }, dispatch)

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(CustomItem)
)
