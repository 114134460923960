import { createMuiTheme } from '@material-ui/core/styles'

const headingFont = {
  fontFamily: '"Slabo 27px", "Georgia", serif',
  fontWeight: 400
}

export default createMuiTheme({
  palette: {
    primary: {
      light: '#215968',
      main: '#215968',
      dark: '#215968',
      contrastText: '#fff'
    },
    secondary: {
      light: '#00D0D3',
      main: '#00D0D3',
      dark: '#00D0D3',
      contrastText: '#000'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    useNextVariants: true,
    h1: headingFont,
    h2: headingFont,
    h3: headingFont,
    h4: headingFont,
    h5: headingFont,
    h6: headingFont
  }
})
