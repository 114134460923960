export const FETCH_COLLECTIONS = `collections/FETCH_COLLECTIONS`

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS:
      return action.collections

    default:
      return state
  }
}
