import { FETCH_PRODUCTS } from './products'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return action.products.reduce((acc, product) => {
        const variants = product.variants.reduce((varAcc, variant) => {
          return {
            ...varAcc,
            [variant.id]: {
              price: variant.price,
              selectedOptions: variant.selectedOptions,
              product: product.title,
              images: product.images
            }
          }
        }, {})
        return { ...acc, ...variants }
      }, {})

    default:
      return state
  }
}
