import React from 'react'
import { Typography, Paper, Fab } from '@material-ui/core'
import { get } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { currentVariant } from './selectors'
import { removeVariantFromCart } from '../../reducers/cart'
import { drawerWidths } from '../CartDrawer'

const styles = theme => ({
  paper: {
    ...drawerWidths(theme),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    position: 'relative'
  },
  price: {
    fontWeight: 'bold'
  },
  product: {
    marginBottom: theme.spacing.unit * 3
  },
  imageDelete: {
    position: 'absolute',
    color: '#fff',
    backgroundColor: '#AA7777',
    top: theme.spacing.unit,
    right: theme.spacing.unit
  },
  container: {
    display: 'flex'
  },
  responsiveImage: {
    width: '10vw',
    height: '15vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    marginRight: theme.spacing.unit
  }
})

const NonCustomItem = ({ lineItem, classes, removeVariantFromCart }) => (
  <Paper className={classes.paper}>
    <Fab
      onClick={() => removeVariantFromCart(lineItem.id)}
      size="small"
      aria-label="Remove Image"
      className={classes.imageDelete}>
      <CloseIcon />
    </Fab>
    <div className={classes.container}>
      <div
        style={{
          backgroundImage: `url(${get(lineItem.variant, 'image.src')})`
        }}
        className={classes.responsiveImage}
      />
      <div>
        <Typography variant="h6">{lineItem.title}</Typography>
        <Typography variant="body1">{`$${lineItem.variant.price}`}</Typography>
        <Typography variant="body1">Qty {lineItem.quantity}</Typography>
      </div>
    </div>
  </Paper>
)

const mapStateToProps = (state, props) => ({
  variant: currentVariant(state, props)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ removeVariantFromCart }, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NonCustomItem)
)
