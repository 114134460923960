import { omit } from 'lodash'
import { RESET } from './orderForm'

export const TOGGLE_SELECT = `orderForm/TOGGLE_SELECT`
export const CHANGE_QUANTITY = `orderForm/CHANGE_QUANTITY`
export const CHANGE_COLOR = `orderForm/CHANGE_COLOR`
export const CHANGE_VARIANT = `orderForm/CHANGE_VARIANT`
export const CHANGE_PLACEMENT = `orderForm/CHANGE_PLACEMENT`
export const CHANGE_PAINT = `orderForm/CHANGE_PAINT`
export const REMOVE_ITEM = `orderForm/REMOVE_ITEM`

const defaultItem = {
  selected: false,
  quantity: 0
}

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SELECT:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          selected: !(state[action.key] ? state[action.key] : defaultItem)
            .selected,
          quantity: !(state[action.key] ? state[action.key] : defaultItem)
            .selected
            ? 1
            : 0
        }
      }

    case CHANGE_QUANTITY:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          quantity: Math.min(
            10,
            Math.max(
              0,
              (state[action.key] ? state[action.key] : defaultItem).quantity +
                (action.increase ? 1 : -1)
            )
          ),
          selected:
            Math.max(
              0,
              (state[action.key] ? state[action.key] : defaultItem).quantity +
                (action.increase ? 1 : -1)
            ) > 0
        }
      }

    case REMOVE_ITEM:
      return omit(state, action.item)

    case CHANGE_COLOR:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          color: action.color
        }
      }

    case CHANGE_VARIANT:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          variant: action.variant
        }
      }

    case CHANGE_PLACEMENT:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          placement: action.placement
        }
      }

    case CHANGE_PAINT:
      return {
        ...state,
        [action.key]: {
          ...(state[action.key] ? state[action.key] : defaultItem),
          paint: action.paint
        }
      }

    case RESET:
      return { ...initialState }

    default:
      return state
  }
}

export const toggleSelect = item => dispatch => {
  dispatch({ type: TOGGLE_SELECT, key: item })
}

export const changeQty = (item, increase) => dispatch => {
  dispatch({ type: CHANGE_QUANTITY, key: item, increase })
}

export const changeColor = (item, color) => dispatch => {
  dispatch({ type: CHANGE_COLOR, key: item, color })
}

export const changeVariant = (item, variant) => dispatch => {
  dispatch({ type: CHANGE_VARIANT, key: item, variant })
}

export const changePlacement = (item, placement) => dispatch => {
  dispatch({ type: CHANGE_PLACEMENT, key: item, placement })
}

export const changePaint = (item, paint) => dispatch => {
  dispatch({ type: CHANGE_PAINT, key: item, paint })
}

export const removeItem = item => dispatch => {
  dispatch({ type: REMOVE_ITEM, item })
}
