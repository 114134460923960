import { createSelector } from 'reselect'
import { filter, groupBy } from 'lodash'
import { has, find } from 'lodash'

const getLineItems = state => state.cart.lineItems
const getCurrentVariantId = (_, props) => props.variantId
const getVariants = state => state.variants

export const customLineItems = createSelector(
  getLineItems,
  lineItems =>
    filter(
      lineItems,
      line => line.customAttributes && line.customAttributes.length > 0
    )
)

export const nonCustomLineItems = createSelector(
  getLineItems,
  lineItems =>
    filter(
      lineItems,
      line =>
        !has(line, 'customAttributes') ||
        (line.customAttributes && line.customAttributes.length === 0)
    )
)

export const groupedCustomItems = createSelector(
  customLineItems,
  (lineItems, variants) => {
    const flattened = lineItems.map(el => {
      const designNameEl = find(
        el.customAttributes,
        attr => attr.key === 'designName'
      )
      const designName = designNameEl ? designNameEl.value : 'Unnamed Design'
      return {
        ...el,
        designName
      }
    })
    return groupBy(flattened, el => el.designName)
  }
)

export const currentVariant = createSelector(
  getVariants,
  getCurrentVariantId,
  (variants, currentVariant) => variants[currentVariant]
)

export const numItems = createSelector(
  getLineItems,
  lineItems => lineItems.reduce((acc, line) => (acc += line.quantity), 0)
)
