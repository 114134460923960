import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import Sherp from '../images/dune.png'
import { APP_VIEW_MARGIN_LEFT_RIGHT, APP_VIEW_MARGIN_TOP_BOTTOM } from '../App'

const styles = theme => ({
})

const TextBanner = ({ classes, content }) =>(
  <div>
  <Typography gutterBottom variant="h3">Under Construction</Typography>
  <Typography gutterBottom variant="body1">We are currently offline while we make changes to our website and equipment. If you would like to place a custom order, please email us at <a href="mailto:orders@dunedogcollective.com">orders@dunedogcollective.com</a></Typography>
  <img src={Sherp} alt="Dune Dog Collective"/>
  </div>

  )

const mapStateToProps = state => ({
})

export default withStyles(styles)(connect(mapStateToProps)(TextBanner))
