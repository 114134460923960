import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar } from '@material-ui/core'
import { push } from 'connected-react-router'
import DuneDogLogo from '../images/logo-site.svg'
import AppMenu from './AppMenu'

const styles = theme => ({
  logo: {
    width: 225,
    margin: `${theme.spacing.unit}px 0`
  },
  buttonContainer: {
    flexGrow: 1
  },
  button: {
    cursor: 'pointer',
    border: 0,
    background: theme.palette.common.white,
    outline: 0,
    ':focus': {
      outline: 0
    }
  }
})

const DuneDogAppBar = ({ push, classes }) => (
  <AppBar position="static" color="inherit">
    <Toolbar>
      <span className={classes.buttonContainer}>
        <button className={classes.button} onClick={() => push('/')}>
          <img
            className={classes.logo}
            src={DuneDogLogo}
            alt="Dune Dog Collective"
          />
        </button>
      </span>
    </Toolbar>
  </AppBar>
)

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push
    },
    dispatch
  )

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(DuneDogAppBar)
)
