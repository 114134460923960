import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import DuneDogFooter from '../images/dunepaw.svg'
import { APP_VIEW_MARGIN_LEFT_RIGHT, APP_VIEW_MARGIN_TOP_BOTTOM } from '../App'

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100vw',
    height: '100%',
    margin: `0 -${APP_VIEW_MARGIN_LEFT_RIGHT}`,
    padding: `${APP_VIEW_MARGIN_TOP_BOTTOM} ${APP_VIEW_MARGIN_LEFT_RIGHT}`,
    backgroundColor: theme.palette.grey[100],
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  left: {
    flex: '1 1 auto',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  right: {
    flex: '2 2 auto'
  },
  logo: {
    height: '20vh',
    [theme.breakpoints.down('sm')]: {
      height: '6vh'
    }
  },
  linkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  bulletless: {
    listStyleType: 'none',
    padding: 0,
    textAlign: 'center',
    color: '#215968'
  },
  columnHead: {
    color: '#AA7777',
    fontWeight: 'strong',
    textAlign: 'center'
  },
  linkColor: {
    color: '#215968'
  }
})

const Footer = ({ push, classes }) => (
  <div className={classes.container}>
    <div className={classes.left}>
      <img
        className={classes.logo}
        src={DuneDogFooter}
        alt="Dune Dog Collective"
      />
    </div>
    <div className={classes.right}>
      <div className={classes.linkContainer}>
        <div>
          <Typography className={classes.columnHead} variant="h5">
            Legal Stuff
          </Typography>
          <ul className={classes.bulletless}>
            <li>
              <Link className={classes.linkColor} to="/refund-policy">
                Refund Policy
              </Link>
            </li>
            <li>
              <Link className={classes.linkColor} to="/privacy-policy-terms">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography className={classes.columnHead} variant="h5">
            Our Story
          </Typography>
          <ul className={classes.bulletless}>
            <li>
              <Link className={classes.linkColor} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className={classes.linkColor} to="/faqs">
                FAQs
              </Link>
            </li>
            <li>
              <Link className={classes.linkColor} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography className={classes.columnHead} variant="h5">
            Shop
          </Typography>

          <ul className={classes.bulletless}>
            <li>
              <Link className={classes.linkColor} to="/find-inspiration">
                Find Inspiration
              </Link>
            </li>
            <li>
              <Link
                className={classes.linkColor}
                to="/collections/feel-good-shop">
                Feel Good Shop
              </Link>
            </li>
            <li>
              <Link className={classes.linkColor} to="/order">
                Custom Order Form
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch)

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(Footer)
)
