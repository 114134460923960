import React from 'react'
import './CssDog.scss'

const CssDog = () => (
  <div
    className="cssDogContainer"
    style={{
      display: 'flex',
      width: '100%',
      height: '90vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <div className="dog">
      <div className="dog-body">
        <div className="dog-tail">
          <div className="dog-tail">
            <div className="dog-tail">
              <div className="dog-tail">
                <div className="dog-tail">
                  <div className="dog-tail">
                    <div className="dog-tail">
                      <div className="dog-tail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dog-torso" />
      <div className="dog-head">
        <div className="dog-ears">
          <div className="dog-ear" />
          <div className="dog-ear" />
        </div>
        <div className="dog-eyes">
          <div className="dog-eye" />
          <div className="dog-eye" />
        </div>
        <div className="dog-muzzle">
          <div className="dog-tongue" />
        </div>
      </div>
    </div>

    <div className="ball" tabIndex="0" />
  </div>
)

export default CssDog
