export const CHANGE_STEP_INDEX = `orderForm/CHANGE_STEP_INDEX`
export const CHANGE_DESIGN_TYPE = `orderForm/CHANGE_DESIGN_TYPE`
export const CHANGE_DESIGN_NOTES = `orderForm/CHANGE_DESIGN_NOTES`
export const CHANGE_DESIGN_NAME = `orderForm/CHANGE_DESIGN_NAME`
export const CHANGE_DESIGN_DESCRIPTION = `orderForm/CHANGE_DESIGN_DESCRIPTION`
export const CHANGE_DESIGN_FONT = `orderForm/CHANGE_DESIGN_FONT`
export const CHANGE_DESIGN_PAINT_INSTRUCTIONS = `orderForm/CHANGE_DESIGN_PAINT_INSTRUCTIONS`
export const ADD_IMAGE = `orderForm/ADD_IMAGE`
export const REMOVE_IMAGE = `orderForm/REMOVE_IMAGE`
export const RESET = `orderForm/RESET`

const initialState = {
  stepIndex: 0,
  designType: null, // upload or text
  designNotes: '',
  designName: '',
  designDescription: '',
  designFont: '',
  designPaintInstructions: '',
  designImage: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.stepIndex
      }
    case CHANGE_DESIGN_TYPE:
      if (!['upload', 'text'].includes(action.designType)) {
        throw new Error('Design Type Unknown')
      }
      return {
        ...state,
        designType: action.designType
      }

    case CHANGE_DESIGN_NOTES:
      return {
        ...state,
        designNotes: action.designNotes
      }

    case CHANGE_DESIGN_NAME:
      return {
        ...state,
        designName: action.designName
      }

    case CHANGE_DESIGN_DESCRIPTION:
      return {
        ...state,
        designDescription: action.designDescription
      }

    case CHANGE_DESIGN_FONT:
      return {
        ...state,
        designFont: action.designFont
      }

    case CHANGE_DESIGN_PAINT_INSTRUCTIONS:
      return {
        ...state,
        designPaintInstructions: action.designPaintInstructions
      }

    case ADD_IMAGE:
      return {
        ...state,
        designImage: action.image
      }

    case REMOVE_IMAGE:
      return {
        ...state,
        designImage: initialState.image
      }

    case RESET:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export const changeStepIndex = stepIndex => dispatch => {
  dispatch({ type: CHANGE_STEP_INDEX, stepIndex })
}

export const setDesignType = designType => dispatch => {
  dispatch({ type: CHANGE_DESIGN_TYPE, designType })
}

export const setDesignNotes = designNotes => dispatch => {
  dispatch({ type: CHANGE_DESIGN_NOTES, designNotes })
}

export const setDesignName = designName => dispatch => {
  dispatch({ type: CHANGE_DESIGN_NAME, designName })
}

export const setDesignDescription = designDescription => dispatch => {
  dispatch({ type: CHANGE_DESIGN_DESCRIPTION, designDescription })
}

export const setDesignFont = designFont => dispatch => {
  dispatch({ type: CHANGE_DESIGN_FONT, designFont })
}

export const setDesignPaintInstructions = designPaintInstructions => dispatch => {
  dispatch({ type: CHANGE_DESIGN_PAINT_INSTRUCTIONS, designPaintInstructions })
}

export const addImage = image => dispatch => {
  dispatch({ type: ADD_IMAGE, image })
}

export const removeImage = () => dispatch => {
  dispatch({ type: REMOVE_IMAGE })
}
