import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Button, MenuItem, Menu, IconButton, Badge } from '@material-ui/core'
import { push } from 'connected-react-router'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import CartIcon from '@material-ui/icons/ShoppingCart'
import { openCart } from '../reducers/cart'
import { numItems } from '../components/CartDrawer/selectors'

import PopupState, {
  bindHover,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 30,
    margin: `${theme.spacing.unit}px 0`
  },
  button: {
    cursor: 'pointer',
    color: '#215968',
    border: 0,
    background: 'rbga(0,0,0,0)',
    outline: 0,
    ':focus': {
      outline: 0
    }
  },
  popover: {
    pointerEvents: 'none'
  },
  badge: {
    top: 10,
    right: 10
  },
  large: {
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  small: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
})

const AppMenu = ({ classes, push, openCart, numItems }) => (
  <div className={classes.container}>
    <span className={classes.large}>
      <PopupState variant="popover" popupId="aboutButton">
        {popupState => (
          <span>
            <Button
              {...bindHover(popupState)}
              {...bindTrigger(popupState)}
              className={classes.button}
              size="large">
              Home <DownIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/about')
                }}>
                About Us
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/guide')
                }}>
                Order Guide
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/faqs')
                }}>
                FAQs
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/contact')
                }}>
                Contact Us
              </MenuItem>
            </Menu>
          </span>
        )}
      </PopupState>
      <Button
        size="large"
        onClick={() => push('/find-inspiration')}
        className={classes.button}>
        Inspiration
      </Button>
      <Button
        size="large"
        onClick={() => push('/collections/feel-good-shop')}
        className={classes.button}>
        Feel Good Shop
      </Button>
      <Button
        size="large"
        onClick={() => push('/order')}
        className={classes.button}>
        Custom
      </Button>
    </span>
    <span className={classes.small}>
      <PopupState variant="popover" popupId="responsive">
        {popupState => (
          <span>
            <Button
              {...bindHover(popupState)}
              {...bindTrigger(popupState)}
              className={classes.button}>
              Menu <DownIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/find-inspiration')
                }}>
                Find Inspiration
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/collections/feel-good-shop')
                }}>
                Feel Good Shop
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/order')
                }}>
                Custom Order
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/about')
                }}>
                About Us
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/faqs')
                }}>
                FAQs
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/guide')
                }}>
                Order Guide
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  push('/contact')
                }}>
                Contact Us
              </MenuItem>
            </Menu>
          </span>
        )}
      </PopupState>
    </span>
    {numItems > 0 && (
      <Badge
        classes={{ badge: classes.badge }}
        badgeContent={numItems}
        color="primary">
        <IconButton onClick={openCart} aria-label="Shopping cart">
          <CartIcon />
        </IconButton>
      </Badge>
    )}
    {numItems === 0 && (
      <IconButton onClick={openCart} aria-label="Shopping cart">
        <CartIcon />
      </IconButton>
    )}
  </div>
)

const mapStateToProps = state => ({
  numItems: numItems(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      openCart
    },
    dispatch
  )

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppMenu)
)
