import striptags from 'striptags'
import { graphqlQuery } from '../helpers/shopify'

export const SET_CONTENT = 'content/SET_CONTENT'
const initialState = {}

const TAG_WHITELIST = [
  'about us',
  'faqs',
  'privacy policy',
  'refund policy',
  'feel good learn more',
  'guide',
  'home block 1',
  'home block 2',
  'home block 3'
]

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENT:
      return action.content

    default:
      return state
  }
}

export const getContent = () => dispatch => {
  graphqlQuery(`{pages(first:250) {edges {node {title body}}}}`).then(res => {
    const content = res.body.data.pages.edges.reduce((acc, page) => {
      const title = page.node.title.toLowerCase().trim()
      return {
        ...acc,
        [title]: TAG_WHITELIST.includes(title)
          ? page.node.body
          : striptags(page.node.body)
      }
    }, {})
    dispatch({
      type: SET_CONTENT,
      content
    })
  })
}
