import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { get } from 'lodash'
import { persistStore } from 'redux-persist'
import ReactGA from 'react-ga'
import thunk from 'redux-thunk'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const enhancers = []
  const middleware = [thunk, routerMiddleware(history)]

  if (process.env.NODE_ENV === 'development') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__())
    }
  } else {
    ReactGA.initialize('UA-116370644-3')
    const ga = store => next => action => {
      const previousPath = get(store.getState(), 'router.location.pathname')
      const result = next(action)
      const nextPath = get(store.getState(), 'router.location.pathname')
      if (previousPath && nextPath && previousPath !== nextPath) {
        ReactGA.pageview(nextPath)
      }
      return result
    }
    middleware.push(ga)
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composedEnhancers
  )
  const persistor = persistStore(store)
  return { store, persistor, history }
}
