export const FETCH_PRODUCTS = `collections/FETCH_PRODUCTS`

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return action.products

    default:
      return state
  }
}
