import React from 'react'
import { CssBaseline, Grid, CircularProgress } from '@material-ui/core'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { MuiThemeProvider } from '@material-ui/core/styles'
import AppBar from './common/DuneDogAppBar'
import Footer from './containers/Footer'
import theme from './theme'
import configureStore, { history } from './configureStore'
import CartDrawer from './components/CartDrawer'
import UnderConstruction from './components/UnderConstruction'
import { Route, Switch, Redirect } from 'react-router-dom'
import withContent from './hocs/withContent'
import ScrollToTop from './hocs/scrollToTop'
import {
  About,
  Contact,
  FrequentlyAskedQuestions,
  Homepage,
  Inspiration,
  OrderForm,
  ViewCollection,
  ViewProduct,
  RefundPolicy,
  PrivacyPolicy,
  Guide
} from './common/Chunks'

export const APP_VIEW_MARGIN_TOP_BOTTOM = '5vh'
export const APP_VIEW_MARGIN_LEFT_RIGHT = '10vw'

const { store, persistor } = configureStore()
const App = props => (
  <Provider store={store}>
    <PersistGate loading={<CircularProgress />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline />
              <AppBar />
              <Grid
                container
                direction="column"
                style={{
                  padding: `${APP_VIEW_MARGIN_TOP_BOTTOM} ${APP_VIEW_MARGIN_LEFT_RIGHT} 0 ${APP_VIEW_MARGIN_LEFT_RIGHT}`,
                  minHeight: '100vh'
                }}>
                <Grid item xs={12} style={{ flex: 1 }}>
                  <Switch>
                    <Route component={UnderConstruction} />
                  </Switch>
                </Grid>
              </Grid>
            </React.Fragment>
          </MuiThemeProvider>
        </ScrollToTop>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

export const activeStore = store
export default App
