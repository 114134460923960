import loadable from '@loadable/component'

export const Homepage = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/Homepage')
)
export const OrderForm = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/OrderForm')
)
export const ViewCollection = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/ViewCollection')
)
export const ViewProduct = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/ViewProduct')
)
export const FrequentlyAskedQuestions = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/FrequentlyAskedQuestions')
)
export const About = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/About')
)
export const Inspiration = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/Inspiration')
)
export const Contact = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/Contact')
)
export const RefundPolicy = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/RefundPolicy')
)
export const PrivacyPolicy = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/PrivacyPolicy')
)
export const Guide = loadable(() =>
  import(/* webpackPrefetch: true */ '../containers/Guide')
)
